import {
  TRACK_EVENT,
  VALIDATE_ACCOUNT_NUMBER,
  GET_INDIVIDUAL_SERVICER_ACCOUNT,
  UPLOAD_STATEMENT,
  GET_UPLOADED_STATEMENT,
  FINICITY_CONNECT_SUCCESS,
  GET_TRANSACTIONS,
  GET_MATCH_TRANSACTIONS,
  SEND_FEEDBACK,
  GET_REFI_VENDORS,
  SAVE_RETURN_TRIP,
  ADD_MISSING_SERVICER,
  ADD_LOAN,
  DELETE_LOAN,
  RESET_DELETED_LOAN_STATE,
  UPDATE_LOAN,
  GET_LOAN_TYPE,
  GET_LOAN_BY_ID,
  GET_ADVISOR_SERVICERS,
  UPLOAD_NSLDS,
  NSLDS_LOAN_IMPORT,
  NSLDS_LOAN_2FA,
  NSLDS_ATTEMPT_ID,
  NSLDS_ATTEMPT_LATEST,
  ACCEPT_ADVISOR_SERVICE_AGREEMENT,
  FETCH_ADVISOR_SERVICE_AGREEMENT,
  CHECK_FORGIVENESS_ELIGIBILITY,
  GET_PELL_GRANTS,
  FETCH_FINICITY_CONNECT_LINK,
  GET_HISTORICAL_MATCH_TRXES
} from '../actions/types';

const initialState = {
  advisorServiceAgreementData: {},
  acceptAdvisorServiceAgreementData: {},
  nsldsLoan: {},
  nsldsLoan2fa:{},
  nsldsAttemptData: {},
  uploadNsldsResponse: {},
  advisorServicers: [],
  loan: {},
  loanTypeData: [],
  updateLoanData: {},
  deleteLoanData: {},
  addLoanData: {},
  addMissingServicerResponse: {},
  returnTrip: {},
  refiVendors: [],
  emailFeedbackResponse: null,
  transactions: [],
  finicityConnectResponse: null,
  uploadStatementResponse: null,
  individualServicerAccount: null,
  validateAccountNumberResponse: null,
  trackEventResponse: null
}

export default function advisorReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADVISOR_SERVICE_AGREEMENT:
      return {
        ...state,
        advisorServiceAgreementData: action.payload
      }
    case ACCEPT_ADVISOR_SERVICE_AGREEMENT:
      return {
        ...state,
        acceptAdvisorServiceAgreementData: action.payload
      }
    case NSLDS_LOAN_IMPORT:
      return {
        ...state,
        nsldsLoan: action.payload
      }
    case NSLDS_LOAN_2FA:
      return {
        ...state,
        nsldsLoan2fa: action.payload
      }
    case NSLDS_ATTEMPT_ID:
      return {
        ...state,
        nsldsAttemptData: action.payload
      }
    case NSLDS_ATTEMPT_LATEST:
      return {
        ...state,
        nsldsAttemptData: action.payload
      }
    case UPLOAD_NSLDS:
      return {
        ...state,
        uploadNsldsResponse: action.payload
      }
    case GET_ADVISOR_SERVICERS:
      return {
        ...state,
        advisorServicers: action.payload
      }
    case GET_LOAN_BY_ID:
      return {
        ...state,
        loan: action.payload
      }
    case GET_LOAN_TYPE:
      return {
        ...state,
        loanTypeData: action.payload
      }
    case UPDATE_LOAN:
      return {
        ...state,
        updateLoanData: action.payload
      }
    case DELETE_LOAN:
      return {
        ...state,
        deleteLoanResponse: 1
      }
    case RESET_DELETED_LOAN_STATE:
      return {
        ...state,
        deleteLoanResponse: 0
      }
    case ADD_LOAN:
      return {
        ...state,
        addLoanData: action.payload
      }
    case ADD_MISSING_SERVICER:
      return {
        ...state,
        addMissingServicerResponse: action.payload
      }
    case SAVE_RETURN_TRIP:
      return {
        ...state,
        returnTrip: action.payload
      }
    case GET_REFI_VENDORS:
      return {
        ...state,
        refiVendors: action.payload
      }
    case SEND_FEEDBACK:
      return {
        ...state,
        emailFeedbackResponse: action.payload
      }
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      }
    case GET_MATCH_TRANSACTIONS:
      return {
        ...state,
        match_transactions: action.payload
      }
    case FINICITY_CONNECT_SUCCESS:
      return {
        ...state,
        finicityConnectResponse: action.payload
      }
    case UPLOAD_STATEMENT:
      return {
        ...state,
        uploadStatementResponse: action.payload
      }
    case GET_UPLOADED_STATEMENT:
      return {
        ...state,
        uploadedStatements: action.payload
      }
    case GET_INDIVIDUAL_SERVICER_ACCOUNT:
      return {
        ...state,
        individualServicerAccount: action.payload
      }
    case VALIDATE_ACCOUNT_NUMBER:
      return {
        ...state,
        validateAccountNumberResponse: action.payload
      }
    case TRACK_EVENT:
      return {
        ...state,
        trackEventResponse: action.payload
      }
    case CHECK_FORGIVENESS_ELIGIBILITY:
      return {
        ...state,
        forgivenessEligibilityMessage: action.payload
      }
    case GET_PELL_GRANTS:
      return {
        ...state,
        pellGrants: action.payload
      }
    case FETCH_FINICITY_CONNECT_LINK:
      return {
        ...state,
        finicityConnectLink: action.payload
      }
    case GET_HISTORICAL_MATCH_TRXES:
      return {
        ...state,
        historicalMatchTrxes: action.payload
      }
    default:
      return state;
  }
}