import React, { Component } from 'react';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { LowerPaymentsSvg, PayoffFasterSvg, PublicServiceLoanForgivenessSvg, RefinanceSvg, StudentLoanForgivenessSvg } from './AdviceAndTools.constant';
import { fetchEmployer } from '../../../actions/employerActions';
import { connect } from 'react-redux';
import { CheckCircle, Plus } from 'react-bootstrap-icons';
import StudentLoan from '../MasterDashboard/StudentLoan'
import { Redirect } from 'react-router-dom';
import { headerSelector } from '../../Includes/Header/Helpers/serviceSelector';
import adviceAndToolsData from '../../Includes/Header/data/adviceAndToolsData.json';
class AdviceAndTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    document.title = "Vault Advisor | Advice and Tools"
    intercomTrackEvent(eventNames.visitedAdvisorDashboard);
    const { fetchEmployer } = this.props;
    fetchEmployer();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.employer !== this.props.employer) {
      this.setState({
        employer: nextProps.employer
      })
    }
  }

  redirectLowerPayment = (e) => {
    this.props.history.push({
      pathname: '/advice-and-tools/lower-payment'
    })
    intercomTrackEvent(eventNames.clickedAdvisorLowerPayTile);
  }
  redirectPayFaster = (e) => {
    this.props.history.push({
      pathname: '/advice-and-tools/pay-faster'
    })
    intercomTrackEvent(eventNames.clickedAdvisorPayOffFasterTile);
  }
  redirectRefinance = (e) => {
    this.props.history.push({
      pathname: '/advice-and-tools/refinance/terms'
    })
    intercomTrackEvent(eventNames.clickedAdvisorRefiTile);
  }
  redirectStudentLoanForgiveness = (e) => {
    this.props.history.push({
      pathname: '/advice-and-tools/forgiveness-eligibility'
    })
    intercomTrackEvent(eventNames.clickedAdvisorRefiTile);
  }
  redirectPublicServiceLoanForgiveness = (e) => {
    this.props.history.push({
      pathname: '/advice-and-tools/public-service-loan-forgiveness'
    })
  }

  checkIfCardToShow = (subHeaderDataList, name) => {
    let findingName = subHeaderDataList.filter(x => x.name.toUpperCase() === name.toUpperCase());
    return findingName.length > 0;
  }

  render() {
    const include_knowledge_bank = !!this.state.employer?.attributes?.include_knowledge_bank
    // const quatroWidget = !!this.state.employer?.attributes?.quatro_widget
    let headerName = headerSelector();
    if (headerName === 'fiveTwoNine' && localStorage.getItem('hasAdvisor') === 'true' && (localStorage.getItem('selectedService') === 'advisor' || (localStorage.getItem('selectedService') === 'null' && localStorage.getItem('advisorFirst') === 'true'))) {
      headerName += 'Advisor'
    }
    let headerSelectedData = [];
    headerSelectedData = adviceAndToolsData.filter((x) => x.service === headerName)

    let subHeaderDataList = headerSelectedData[0]?.data[0]?.subHeader

    if (localStorage.getItem("hasProfile") !== 'true') {
      return (
        <Redirect to='/intro/advisor' />
      )
    }
    return (
      <>
        <Header />
        <div>
          <div className='G-page-background-color'>
            <div className='G-page-main-container my-5'>
              <div className='p-4 bg-white Gborder-1 rounded-lg '>
                <div className='G-grid11-2'>
                  <div>
                    <div className="pinkCircularDiv mb-3">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M28.75 44.4875V39.9875C22.4875 39.6625 17.5 34.4625 17.5 28.125C17.5 21.575 22.825 16.25 29.375 16.25C35.925 16.25 41.25 21.575 41.25 28.125C41.25 34.3125 36.95 40.5375 30.5375 43.625L28.75 44.4875ZM29.375 18.75C24.2 18.75 20 22.95 20 28.125C20 33.3 24.2 37.5 29.375 37.5H31.25V40.375C35.8 37.5 38.75 32.775 38.75 28.125C38.75 22.95 34.55 18.75 29.375 18.75ZM28.125 33.125H30.625V35.625H28.125V33.125ZM30.625 31.25H28.125C28.125 27.1875 31.875 27.5 31.875 25C31.875 23.625 30.75 22.5 29.375 22.5C28 22.5 26.875 23.625 26.875 25H24.375C24.375 22.2375 26.6125 20 29.375 20C32.1375 20 34.375 22.2375 34.375 25C34.375 28.125 30.625 28.4375 30.625 31.25Z" fill="var(--icon-color)" />
                      </svg>
                    </div>
                    <h4>Advice and Tools </h4>
                    <p className="sans-medium">
                      Vault is here to help you make the smartest finance decisions, whether you’re paying down student loans, looking for repayment programs, planning for college, or just fine-tuning your current financial plan. Check out our latest tools and calculators below.
                    </p>
                  </div>
                  <div className='Gborder-green-6 Gborder-1 rounded-lg Gb-green-2 p-4'>
                    <div className='d-flex'>
                      <label>
                        <CheckCircle size={30} className='Gt-green-6' />
                      </label>
                      <label className='Gt-green-6 mx-2 mb-0 Gfs-125'>You got all your loans imported!</label>
                    </div>

                    <p className='sans-medium my-3'>
                      Now that your loans are added, don't forget to explore our Vault tools. They're designed to help you enhance your financial health.
                    </p>
                    <table>
                      <tbody>
                        <tr>
                          <td className='p-2'>
                            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14 12L16.29 9.71L11.41 4.83L7.41 8.83L0 1.41L1.41 0L7.41 6L11.41 2L17.71 8.29L20 6V12H14Z" fill="var(--icon-color)" />
                            </svg>
                          </td>
                          <td className='G-relative-top-2px G-link G-cursor-pointer' onClick={this.redirectLowerPayment.bind(this)}>
                            Lower Payments
                          </td>
                        </tr>
                        <tr>
                          <td className='p-2'>
                            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 12H2C2 13.08 3.37 14 5 14C6.63 14 8 13.08 8 12C8 10.9 6.96 10.5 4.76 9.97C2.64 9.44 0 8.78 0 6C0 4.21 1.47 2.69 3.5 2.18V0H6.5V2.18C8.53 2.69 10 4.21 10 6H8C8 4.92 6.63 4 5 4C3.37 4 2 4.92 2 6C2 7.1 3.04 7.5 5.24 8.03C7.36 8.56 10 9.22 10 12C10 13.79 8.53 15.31 6.5 15.82V18H3.5V15.82C1.47 15.31 0 13.79 0 12Z" fill="var(--icon-color)" />
                            </svg>
                          </td>
                          <td className='G-relative-top-2px G-link G-cursor-pointer' onClick={this.redirectRefinance.bind(this)}>
                            Refinance
                          </td>
                        </tr>
                        <tr>
                          <td className='p-2'>
                            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 14H0L7 0V8H12L5 22V14Z" fill="var(--icon-color)" />
                            </svg>
                          </td>
                          <td className='G-relative-top-2px G-link G-cursor-pointer' onClick={this.redirectPayFaster.bind(this)}>
                            Payoff Faster
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='G-grid11-2'>
                  <div className="mt-3">
                    <div className="pinkCircularDiv mb-3">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M30 18.75L16.25 26.25L21.25 28.975V36.475L30 41.25L38.75 36.475V28.975L41.25 27.6125V36.25H43.75V26.25L30 18.75ZM38.525 26.25L30 30.9L21.475 26.25L30 21.6L38.525 26.25ZM36.25 34.9875L30 38.4L23.75 34.9875V30.3375L30 33.75L36.25 30.3375V34.9875Z" fill="var(--icon-color)" />
                      </svg>
                    </div>
                    <h4>My Loans</h4>
                    <p className="sans-medium">
                      Simply import your loans via FSA or manually, and watch your personalized debt-reducing plan unfold!
                    </p>
                  </div>

                </div>
                <div className='d-flex mob-btn-div-column'>
                  <div className="mr-2 mb-3">
                    <a href='/advice-and-tools/loans/new'>
                      <button className="bg-transparent border-0 G-orange-button w-100" >
                        <Plus size={20} className='mr-1 mb-1' /> Add a loan
                      </button>
                    </a>
                  </div>
                  <div>
                    <a href='/advice-and-tools/nslds' >
                      <button className="G-cancel-button w-100" >
                        Add loans with your FSA account
                      </button>
                    </a>
                  </div>
                </div>
                <StudentLoan />
                <div className='G-grid11-2 mt-4'>

                  {this.checkIfCardToShow(subHeaderDataList, 'LOWER PAYMENTS') &&
                    <div className='Gborder-05 p-4 rounded-lg bg-white'>
                      <div className='Gb-blue-50 text-center'>
                        <LowerPaymentsSvg />
                      </div>
                      <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>Lower Payments</h3>
                      <p className='sans-medium mb-4'>Federal repayment plans may lower your monthly payments. See if you may be eligible</p>
                      <button className='G-cancel-button' onClick={this.redirectLowerPayment.bind(this)}>Lower Payments</button>
                    </div>
                  }
                  {this.checkIfCardToShow(subHeaderDataList, 'REFINANCE') &&
                    <div className='Gborder-05 p-4 rounded-lg bg-white'>
                      <div className='Gb-yellow-2 text-center'>
                        <RefinanceSvg />
                      </div>
                      <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>Refinance</h3>
                      <p className='sans-medium mb-4'>Replaces your current loans with a new one. Calculate the impact of refinancing.</p>
                      <button className='G-cancel-button' onClick={this.redirectRefinance.bind(this)}>Refinance</button>
                    </div>
                  }
                  {this.checkIfCardToShow(subHeaderDataList, "PAYOFF FASTER") &&
                    <div className='Gborder-05 p-4 rounded-lg bg-white'>
                      <div className='Gb-orange-2 text-center'>
                        <PayoffFasterSvg />
                      </div>
                      <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>Payoff Faster</h3>
                      <p className='sans-medium mb-4'>Paying extra every month may help payoff your loan faster and save you money.</p>
                      <button className='G-cancel-button' onClick={this.redirectPayFaster.bind(this)}>Payoff Faster</button>
                    </div>
                  }

                  {this.checkIfCardToShow(subHeaderDataList, "STUDENT LOAN FORGIVENESS") &&
                    <div className='Gborder-05 p-4 rounded-lg bg-white'>
                      <div className='Gb-green-2 text-center'>
                        <StudentLoanForgivenessSvg />
                      </div>
                      <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>Student Loan Forgiveness</h3>
                      <p className='sans-medium mb-4'>The Biden Administration plans to forgive $10,000 or $20,000 of federal student loans for individuals who did not or did receive Pell Grants respectively. This is applicable to those earning under $125,000 per year (or under $250,000 for households).</p>
                      <button className='G-cancel-button' onClick={this.redirectStudentLoanForgiveness.bind(this)}>Student Loan Forgiveness</button>
                    </div>
                  }
                  {this.checkIfCardToShow(subHeaderDataList, "PUBLIC SERVICE LOAN FORGIVENESS") && include_knowledge_bank &&
                    <div className='Gborder-05 p-4 rounded-lg bg-white'>
                      <div className='Gb-pink-2 text-center'>
                        <PublicServiceLoanForgivenessSvg />
                      </div>
                      <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>Public Service Loan Forgiveness</h3>
                      <p className='sans-medium mb-4'>The PSLF program cancels the remaining balance on your federal loans after 120 qualifying payments have been made under a repayment plan, while you work full-time for a qualifying employer. However, meeting all eligibility requirements is necessary for loan forgiveness.</p>
                      <button className='G-cancel-button' onClick={this.redirectPublicServiceLoanForgiveness.bind(this)}>Public Service Loan Forgiveness</button>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>

    )
  }
}

// Header.propTypes = {
//   fetchEmployer: PropTypes.func.isRequired,
// }

const mapStateToProps = state => ({
  employer: state.employer_array.employerData,
  errors: state.errors
});

export default connect(mapStateToProps, { fetchEmployer })(AdviceAndTools);