/**
 * Provides a map of event names for tracking in intercom and mixpanel.
 */
const events = {
  register: 'signed_up',
  login: 'logged_in',
  presentedTerms: 'presented_terms',
  agreedToAdvisorTerm: 'agreed_to_advisor_term',
  agreedToEsignTerms: 'agreed_to_esign_terms',
  agreedToPayTerms: 'agreed_to_pay_terms',
  agreedToMatchTerms: 'agreed_to_match_terms',
  clickedStartWithPay: 'clicked_start_with_pay_on_bypass_advisor',
  clickedStartWithMatch: 'clicked_start_with_match_on_bypass_advisor',
  clickedStartWithAdvisor: 'clicked_start_with_advisor_on_bypass_advisor',
  clickedGetStartedOnAdvisor: 'clicked_get_started_on_advisor_overview',
  clickedSetupContribution: 'clicked_setup_contribution_in_pay_overview',
  clickedGetStartedOnMatch: 'clicked_get_started_in_match_overview',
  visitedLearnOverview: 'visited_learn_overview',
  clickedGetStartedOrTakeActionOnLearnOverview: 'clicked_get_started_on_learn_overview',
  clickedLearnMoreOnLearnOverview: 'clicked_learn_more_on_learn_overview',
  visitedAdvisorOverview: 'visited_advisor_overview',
  visitedPayOverview: 'visited_pay_overview',
  visitedPayHistory: 'visited_pay_history',
  visitedMatchOverview: 'visited_match_overview',
  visitedPslfInfo: 'visited_pslf_info',
  clickedDeniedPslf: 'clicked_denied_pslf',
  clickedClosedSchoolDischarge: 'clicked_closed_school_discharge',
  clickedBorrowerDefense: 'clicked_borrower_defense_on_repayment',
  clickedUnderstandPslfApplication: 'clicked_understand_pslf_application',
  clickedPslfEmployerAssessmentTool: 'clicked_pslf_employer_assessment_tool',
  clickedGetStartedOnPslfInformationPage: 'clicked_get_started_on_pslf_information_page',
  visitedBypassAdvisorOverview: 'visited_bypass_advisor_overview',
  visitedAdvisorAboutMe: 'visited_advisor_about_me',
  submittedAdvisorAboutMe: 'submitted_advisor_about_me',
  visitedAdvisorNsldsImport: 'visited_advisor_nslds_import',
  updatedAdvisorNsldsSuccess: 'uploaded_advisor_nslds_successfully',
  successAdvisorNslds2fa: 'successful_advisor_nslds_2fa',
  successAdvisorNsldsAttemptId:'successful_advisor_nslds_attempt_id',
  successAdvisorNsldsAttemptLatest:'successful_advisor_nslds_attempt_latest',
  skippedAdvisorNslds: 'skipped_advisor_nslds_upload',
  failedAdvisorNslds: 'failed_advisor_nslds_import',
  failedAdvisorNslds2fa: 'failed_advisor_nslds_2fa',
  failedAdvisorNsldsAttemptId:'failed_advisor_nslds_attempt_id',
  failedAdvisorNsldsAttemptLatest:'failed_advisor_nslds_attempt_latest',
  failedAdvisorNsldsFileUpload: 'failed_advisor_nslds_file_upload',
  successfulAdvisorNsldsFileUpload: 'successful_advisor_nslds_file_upload',
  visitedAdvisorLoans: 'visited_advisor_loans_page',
  visitedAdvisorNewLoan: 'visited_advisor_new_loan_page',
  submittedAdvisorNewLoan: 'submitted_advisor_new_loan_form',
  visitedAdvisorEditLoan: 'visited_advisor_edit_loan_page',
  visitedAdvisorGeniusCalc: 'visited_advisor_genius_calculate',
  clickedGoToDashboardOnCalc: 'clicked_go_to_dash_on_advisor_calculator',
  clickedAdvisorRefiTile: 'clicked_advisor_dashboard_refi_tile',
  visitedAdvisorDashboard: 'visited_advisor_dashboard',
  clickedAdvisorPayOffFasterTile: 'clicked_advisor_dashboard_pay_off_faster_tile',
  clickedAdvisorLowerPayTile: 'clicked_advisor_dashboard_lower_payments_tile',
  clickedAdvisorRefiVendorTop: 'clicked_advisor_dashboard_refi_vendor_top',
  selectedAdvisorCreditScore: 'selected_advisor_dashboard_credit_score',
  clickedAdvisorRefiLearnMore: 'clicked_advisor_dashboard_refi_learn_more',
  clickedAdvisorRefiVendorInPaperwork: 'clicked_advisor_dashboard_refi_vendor_in_paperwork',
  clickedAdvisorRefiTerm: 'clicked_advisor_dashboard_refi_term',
  downloadedAdvisorRefiGuideInPaperwork: 'downloaded_advisor_dash_refi_guide_in_paperwork',
  clickedAdvisorScheduleOneOnOnePaperwork: 'clicked_advisor_dash_schedule_1_on_1_refi_paperwork',
  submittedFeedbackFormAdvisorDashboard: 'submitted_feedback_form_advisor_dash',
  clickedAdvisorFaq: 'clicked_advisor_faq',
  visitedFamilyInvitePage: 'visited_family_invite_page',
  sentFamilyInvite: 'sent_family_invite',
  deletedFamilyInvite: 'deleted_family_invite',
  enabled2fa: 'enabled_2fa',
  disabled2fa: 'disabled_2fa',
  visitedNotificationsPage: 'visited_all_notifications_page',
  clickedOnNotification: 'clicked_on_notification',
  clickedLearnMoreOnPayFaster: 'clicked_learn_more_on_advisor_dash_payoff_faster',
  clickedScheduleOneOnOnePayOffFaster: 'clicked_schedule_1_on_1_advisor_dash_pay_off_faster_paperwork',
  visitedAdvisorFedRepaymentPlan: 'visited_advisor_dash_fed_repayment_plan',
  clickedLearnMoreOnRepaymentPlan: 'clicked_learn_more_on_advisor_dash_repayment_plan',
  downloadedAdvisorWalkthroughRepayPlan: 'downloaded_advisor_dash_walkthrough_guide_for_repayment_plan',
  clickedScheduleOneOnOneRepaymentPlan: 'clicked_schedule_1_on_1_in_advisor_dash_repayment_plan_paperwork',
  visitedMatchDashboard: 'visited_match_dashboard',
  submittedMatchSalary: 'submitted_match_salary',
  uploadedStatementForMatch: 'uploaded_statement_for_match',
  successfullyLinkedFinicityMatch: 'successfully_linked_finicity_for_match',
  failedLinkedFinicityMatch: 'failed_linked_finicity_for_match',
  downloadedPaymentHistoryMatch: 'downloaded_payment_history_for_match',
  relinkedFinicityMatch: 'relinked_finicity_for_match', // NOTE: Can't figure out right now a good way to trigger this. It would be way easier to do in the backend otherwise there's more work to do on the api/client side to get this rolling.
  clickedAddAccountFromMatch: 'clicked_add_account_from_match',
  clickedSeePlanDetailsMatch: 'clicked_see_plan_details_on_match',
  clickedSeePlanDetailsMatchOverview: 'clicked_see_plan_details_on_match_overview',
  clickedSeePlanDetailsPay: 'clicked_see_plan_details_on_pay',
  clickedSeePlanDetailsPayOverview: 'clicked_see_plan_details_on_pay_overview',
  submittedMissingPaymentMatchForm: 'submitted_missing_payment_on_match_form',
  clickedMatchFaq: 'clicked_match_faq',
  successfullyLinkedFinicityPay: 'successfully_linked_finicity_for_pay',
  failedLinkedFinicityPay: 'failed_linked_finicity_for_pay',
  clickedAddAccountFromPaySetup: 'clicked_add_account_from_pay_setup_select_servicer_page',
  clickedEditAccountPayHistory: 'clicked_edit_account_on_pay_history_page',
  clickedPausePayPayment: 'clicked_pause_payment_for_pay', // here
  clickedUnpausePayPayment: 'clicked_unpause_payments_for_pay',
  downloadedPayContribHistory: 'downloaded_pay_contribution_history',
  visitedPaySetupServicer: 'visited_pay_setup_servicer_page',
  selectedServicerPaySetup: 'selected_servicer_on_pay_setup_select_servicer_page',
  visitedPaySetup: 'visited_pay_setup_servicer_setup_page',
  clickedEditServicerPaySetup: 'clicked_edit_servicer_on_pay_setup_servicer_setup_page',
  submittedPaySetupServicer: 'submitted_pay_setup_servicer_setup_page',
  submittedPaySetupMissingServicerAddressForm: 'submitted_pay_setup_missing_servicer_address_form',
  skippedPaySetupAccountNumberValidation: 'skipped_pay_setup_account_number_validation_message',
  clickedGoBackOnAccountNumberValidation: 'clicked_go_back_on_account_number_validation_message_on_pay',
  skippedPaySetupNelnetAddressValidation: 'skipped_pay_setup_nelnet_address_validation_message',
  clickedGoBackOnNelnetAddressValidation: 'clicked_go_back_on_nelnet_address_validation_message_on_pay',
  clickedSubmitEnrollPay: 'clicked_submit_and_enroll_on_pay_confirmation_page',
  clickedPayFaq: 'clicked_pay_faq',
  relinkedFinicityPay: 'relinked_finicity_for_pay', // NOTE: Needs additional logic to know if we need to re-link or just linking.
  visitedPayServicerSelection: 'visited_pay_servicer_selection',
  visitedPayLoanServicerSetup: 'visited_pay_loan_servicer_setup_page',
  vistedPayServicerConfirmation: 'visited_pay_servicer_confirmation_page',
  /**
   * 4/17/2020
   * GENIUS-8160
   * Required advisor events for sl-pay metrics for employers.
   * DO NOT REMOVE/MODIFY WITHOUT DOING SAME TO SL-PAY
   */
  visitedLowerPayments: 'visited-lower-payments',
  selectedLowerPaymentsPlan: 'selected-lower-payments-plan',
  visitedPayFaster: 'visited-pay-faster',
  selectedPayFasterPlan: 'selected-pay-faster-plan',
  setRefinanceInterestRate: 'set-refinance-interest-rate',
  clickedReviewRepaymentWalkthrough: 'clicked-review-repayment-walkthrough',
  clickedRepaymentApply: 'clicked-repayment-apply',
};
export default events;