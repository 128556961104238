import React, { Component } from 'react';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { fetchEmployer } from '../../../actions/employerActions'
import { connect } from 'react-redux';
import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { headerSelector } from '../../Includes/Header/Helpers/serviceSelector';

const AdvisorLogo = () => {
  return (
    <svg className='w-100 h-100' width="626" height="485" viewBox="0 0 700 485" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_2772_10131)">
        <path d="M323.482 203.388C324.963 205.927 327.091 208.036 329.654 209.502C332.217 210.968 335.123 211.74 338.082 211.74C341.041 211.74 343.947 210.968 346.51 209.502C349.073 208.036 351.201 205.927 352.682 203.388L364.127 183.716L375.572 164.067C377.056 161.528 377.839 158.646 377.842 155.711C377.845 152.777 377.067 149.893 375.588 147.352C374.109 144.81 371.98 142.7 369.416 141.233C366.853 139.767 363.944 138.997 360.984 139C358.025 138.999 355.118 139.773 352.556 141.243C349.995 142.712 347.87 144.826 346.396 147.371L323.482 186.785C322.007 189.324 321.23 192.203 321.23 195.133C321.23 198.063 322.007 200.942 323.482 203.481" fill="white" />
        <path d="M312.037 183.716L313.327 185.925L340.58 139H315.18C312.22 138.997 309.312 139.767 306.748 141.233C304.184 142.7 302.055 144.81 300.576 147.352C299.097 149.893 298.319 152.777 298.322 155.711C298.325 158.646 299.108 161.528 300.592 164.067L312.037 183.716Z" fill="white" />
        <path d="M287.106 224.478C288.585 227.017 290.711 229.125 293.272 230.591C295.833 232.056 298.737 232.827 301.694 232.826H302.304C305.264 232.829 308.172 232.059 310.736 230.593C313.3 229.127 315.429 227.016 316.908 224.475C318.387 221.933 319.164 219.049 319.162 216.115C319.159 213.18 318.376 210.298 316.892 207.759L305.165 187.39L293.298 167.02C291.817 164.48 289.689 162.371 287.126 160.905C284.564 159.439 281.657 158.667 278.698 158.667C275.74 158.667 272.833 159.439 270.27 160.905C267.708 162.371 265.579 164.48 264.098 167.02L263.77 167.601C262.29 170.147 261.511 173.034 261.511 175.972C261.511 178.911 262.29 181.798 263.77 184.344L287.106 224.478Z" fill="white" />
        <path d="M254.858 232.826H281.196L253.404 185.227L252.137 187.39L240.27 207.759C238.786 210.298 238.003 213.18 238 216.115C237.997 219.049 238.775 221.933 240.254 224.474C241.733 227.016 243.862 229.127 246.426 230.593C248.99 232.059 251.898 232.829 254.858 232.826Z" fill="white" />
        <path d="M302.023 274.263C304.978 274.259 307.881 273.486 310.441 272.021C313.001 270.556 315.129 268.451 316.61 265.915L316.915 265.38C318.393 262.839 319.17 259.956 319.168 257.022C319.166 254.089 318.386 251.207 316.905 248.667C315.424 246.127 313.296 244.018 310.733 242.552C308.17 241.085 305.263 240.314 302.304 240.314H254.881C251.922 240.314 249.015 241.085 246.452 242.552C243.889 244.018 241.761 246.127 240.28 248.667C238.799 251.207 238.019 254.089 238.017 257.022C238.015 259.956 238.792 262.839 240.27 265.38L240.621 265.962C242.099 268.508 244.228 270.623 246.793 272.093C249.359 273.563 252.27 274.335 255.233 274.333L302.023 274.263Z" fill="white" />
        <path d="M306.268 283.518H250.777L252.043 285.703L263.911 306.073C265.388 308.613 267.514 310.723 270.075 312.19C272.636 313.657 275.541 314.429 278.499 314.429C281.456 314.429 284.362 313.657 286.922 312.19C289.483 310.723 291.609 308.613 293.087 306.073L304.954 285.703L306.268 283.518Z" fill="white" />
        <path d="M352.823 269.729L352.518 269.217C351.037 266.677 348.909 264.568 346.346 263.102C343.783 261.636 340.877 260.865 337.918 260.865C334.959 260.865 332.053 261.636 329.49 263.102C326.927 264.568 324.799 266.677 323.318 269.217L311.404 289.564L299.677 309.933C298.193 312.472 297.41 315.354 297.407 318.289C297.405 321.223 298.182 324.106 299.661 326.648C301.141 329.19 303.269 331.3 305.833 332.767C308.397 334.233 311.305 335.003 314.265 335H314.945C317.908 335 320.818 334.227 323.383 332.757C325.948 331.287 328.078 329.174 329.557 326.629L353.01 286.424C354.486 283.885 355.263 281.007 355.263 278.077C355.263 275.146 354.486 272.267 353.01 269.729" fill="white" />
        <path d="M364.362 289.564L363.072 287.238L335.373 334.907H361.641C364.601 334.91 367.509 334.14 370.073 332.674C372.637 331.207 374.766 329.097 376.245 326.555C377.724 324.014 378.501 321.13 378.499 318.196C378.496 315.261 377.713 312.379 376.229 309.84L364.362 289.564Z" fill="white" />
        <path d="M388.8 248.801C387.321 246.265 385.193 244.16 382.632 242.699C380.071 241.237 377.167 240.471 374.212 240.476H373.602C370.646 240.476 367.742 241.246 365.181 242.712C362.62 244.177 360.494 246.284 359.016 248.822C357.537 251.36 356.758 254.24 356.758 257.171C356.758 260.102 357.536 262.982 359.014 265.52L370.858 285.89L382.726 306.259C384.206 308.799 386.335 310.908 388.898 312.374C391.46 313.84 394.367 314.612 397.326 314.612C400.284 314.612 403.191 313.84 405.754 312.374C408.316 310.908 410.445 308.799 411.925 306.259L412.254 305.678C413.734 303.136 414.513 300.253 414.513 297.318C414.513 294.384 413.734 291.501 412.254 288.959L388.8 248.801Z" fill="white" />
        <path d="M421.143 240.476H394.804L422.597 288.075L423.863 285.89L435.731 265.543C437.215 263.004 437.997 260.122 438 257.188C438.003 254.253 437.226 251.37 435.746 248.828C434.267 246.286 432.138 244.176 429.575 242.71C427.011 241.244 424.102 240.473 421.143 240.476Z" fill="white" />
        <path d="M435.473 207.318C433.99 204.777 431.86 202.668 429.295 201.203C426.73 199.737 423.821 198.967 420.861 198.97H373.954C370.997 198.981 368.094 199.759 365.535 201.228C362.976 202.697 360.849 204.804 359.366 207.341L359.061 207.852C357.584 210.394 356.807 213.277 356.809 216.211C356.811 219.144 357.591 222.026 359.072 224.566C360.552 227.106 362.681 229.215 365.244 230.681C367.807 232.148 370.714 232.919 373.673 232.919H421.096C424.055 232.919 426.962 232.148 429.524 230.681C432.087 229.215 434.216 227.106 435.697 224.566C437.177 222.026 437.958 219.144 437.96 216.211C437.961 213.277 437.185 210.394 435.707 207.852L435.473 207.318Z" fill="white" />
        <path d="M369.733 189.785H425.223L423.957 187.622L412.09 167.252C410.612 164.712 408.486 162.603 405.925 161.136C403.364 159.669 400.459 158.897 397.501 158.897C394.544 158.897 391.638 159.669 389.078 161.136C386.517 162.603 384.391 164.712 382.913 167.252L371.046 187.622L369.733 189.785Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M493.917 98C466.842 98 444.901 119.941 444.901 147.016C444.901 156.253 447.458 164.882 451.902 172.252C450.733 178.434 447.428 187.972 438 191.887C438 191.887 455.837 193.804 467.431 188.241C475.071 193.165 484.159 196.031 493.927 196.031C521.001 196.031 542.942 174.09 542.942 147.016C542.942 119.941 520.981 98 493.917 98ZM460.093 136.956C460.093 134.552 462.042 132.603 464.446 132.603H524.931C527.335 132.603 529.283 134.552 529.283 136.956V140C529.283 142.404 527.335 144.353 524.931 144.353H464.446C462.042 144.353 460.093 142.404 460.093 140V136.956ZM464.446 149.574C462.042 149.574 460.093 151.523 460.093 153.927V156.971C460.093 159.375 462.042 161.324 464.446 161.324H501.432C503.836 161.324 505.785 159.375 505.785 156.971V153.927C505.785 151.523 503.836 149.574 501.432 149.574H464.446Z" fill="white" />
      </g>
      <defs>
        <filter id="filter0_dd_2772_10131" x="0.5" y="0.5" width="675" height="509" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2772_10131" />
          <feOffset dy="18" />
          <feGaussianBlur stdDeviation="44" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2772_10131" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="6" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2772_10131" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="14" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2772_10131" result="effect2_dropShadow_2772_10131" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2772_10131" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_2772_10131" x1="216.5" y1="160.5" x2="619.5" y2="458" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-secondary-color)" />
          <stop offset="1" stopColor="var(--icon-color)" />
        </linearGradient>
      </defs>
    </svg>

  )
}

const CheckLogo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#52C41A" />
    </svg>
  )
}

class Advisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      isOpen: false,
    }
  }
  componentDidMount() {
    document.title = "Vault | Intro"
    const { fetchEmployer, } = this.props;
    fetchEmployer();
    // fetchGeneralAgreement();
    intercomTrackEvent(eventNames.visitedAdvisorOverview);
  }
  goToWelcomeAbout = (e) => {
    intercomTrackEvent(eventNames.clickedGetStartedOnAdvisor);
    this.props.history.push({
      pathname: '/welcome/about'
    })
  }

  render() {
    // const {} = this.props;
    const headerName = headerSelector();
    const logoNameAndLogoutShowOnly = headerName === 'advisorOnly';
    return (

      <>
        <Header
          hideNotificationAndProfileDropdown={true}
          logoNameAndLogoutShowOnly={logoNameAndLogoutShowOnly} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5">
            <div className='G-grid11-2 p-4 bg-white Gborder-05 rounded-lg mob-reverse-1200'>
              <div>
                <h3 className="Gfs-225 mb-4 Gt-slate-5">
                  Empower Your Financial Freedom
                  <span className="G-text-grad sans-bold">

                    &nbsp;with Vault Advisor
                  </span>
                </h3>
                <p className="sans-medium">
                  <label className='mr-2 mb-0'><CheckLogo /></label>
                  Vault Advisor helps you manage your debt smarter.
                </p>
                <p className="sans-medium">
                  <span className='mr-2'><CheckLogo /></span>
                  Works with all types of loans, not just student loans.
                </p>
                <p className="sans-medium">
                  <span className='mr-2 my-auto'><CheckLogo /></span>
                  Gives you a full picture of your debts.
                </p>
                <p className="sans-medium">
                  <span className='mr-2'><CheckLogo /></span>
                  Lets you create a repayment plan that suits you.
                </p>
                <p className="sans-medium">
                  <span className='mr-2'><CheckLogo /></span>
                  Import loans directly from FSA or add them manually.
                </p>
                <p className="sans-medium">
                  <span className='mr-2'><CheckLogo /></span>
                  Start making better financial decisions today.
                </p>
                <p className="sans-medium">
                  and more...
                </p>
                <div className='d-flex mob-btn-div-column'>

                  <button onClick={(e) => this.goToWelcomeAbout()} className='G-orange-button'>Manage Your Loans Now</button>
                </div>
              </div>
              <div className="introAdvisorBannerHeight my-auto rounded-lg tuitionIntroBannerBackground">
                <AdvisorLogo />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => ({
  employer: state.employer_array.employerData,

});


export default connect(mapStateToProps, { fetchEmployer, })(Advisor);