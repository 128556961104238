import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { scroller } from "react-scroll";
import SimpleReactValidator from "simple-react-validator";
import { responseMessage } from "../../../../utils/alert";

import repayment_calculator from "program_calculator";

import Header from "../../../Includes/Header/Header";
import Footer from "../../../Includes/Footer/Footer";

import PayFasterExtraWidget from "./PayFasterExtraWidget";
import PayFasterPaperWork from "./PayFasterPaperWork";

import {
  saveReturnTrip,
  sendEmailFeedback,
  trackEvent,
} from "../../../../actions/advisorActions";
import {
  fetchCustomer,
  updateCustomer,
} from "../../../../actions/customerActions";
import {
  getLoans,
  getEligibilityResults,
} from "../../../../actions/fiveTwoNineActions";

import { convertCamelCaseCustomer } from "../../../../common/js/jsCustomer";
import { convertHyphensCamelCaseLoansCustom } from "../../../../common/js/jsCustomFunctions";

import { currency_format, eligibilityLoansJoins } from "../../eligibility-result";

import {
  calculatorTranslatorData,
  calculatorResult,
  calculatorResultWithMatch,
  calculatorResultWithExtra,
  calculatorResultWithMatchAndExtra,
} from "../../calculator-result";

import { intercomTrackEvent } from "../../../customerStatus";
import eventNames from "../../../event-names-map";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { payoffTimeInWords } from "../../../../common/js/jsFunctions";
import Breadcrumb from "../../../shared/Breadcrumb/Breadcrumb";
import { AdviceAndToolPayFasterBreadcrumb } from '../../CommonBreadcrumb/Breadcrumb.constant'
import Loader from "../../../Includes/Loader/Loader";

class PayFaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      customer_array: {},
      loans: [],
      eligibilityResults: [],
      existingPlan: {},
      existingLoans: [],
      existingLoansAdvisable: false,
      matchEnabled: false,
      extraAmountValue: 0,
      showPaperwork: false,
      feedback: "",
      shouldConvertCentsToDollars: true,
      learnMoreTypesShow: false,
      externalLoading: true,
    };
    this.validator = new SimpleReactValidator({
      element: (message) => {
        return <p className="text-light">{message}</p>;
      },
      autoForceUpdate: this,
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor | Payoff Faster";
    const {
      saveReturnTrip,
      fetchCustomer,
      getLoans,
      getEligibilityResults,
      trackEvent,
    } = this.props;
    saveReturnTrip();
    fetchCustomer();
    getLoans();
    getEligibilityResults();

    this.timerID = setTimeout(() => {
      this.calculateExistingLoans();
      this.setState({ externalLoading: false })
    }, 5000);

    const url = this.props.match.url;
    const lastSegment = url.split("/").pop();
    if (lastSegment === "details") {
      this.setState({
        showPaperwork: true,
      });
    }
    const data = {
      data: {
        event_key: eventNames.visitedPayFaster,
      },
    };
    trackEvent(data);
  }
  componentWillUnmount() {
    clearTimeout(this.timerID);
  }
  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      const customerData = this.props.customer;
      const customer = convertCamelCaseCustomer(customerData);
      this.setState({
        customer: customer,
        customer_array: customerData,
      });
    }
    if (this.props.loans !== prevProps.loans) {
      const loansData = this.props.loans;
      const loans = convertHyphensCamelCaseLoansCustom(loansData);
      this.setState({
        loans: loans,
      });
    }
    if (this.props.eligibilityResults !== prevProps.eligibilityResults) {
      const eligibilityResultsData = this.props.eligibilityResults;
      const eligibilityLoans = eligibilityLoansJoins(eligibilityResultsData);
      this.setState({
        eligibilityResults: eligibilityLoans,
      });
    }
    if (this.props.emailFeedbackResponse !== prevProps.emailFeedbackResponse) {
      responseMessage(
        "success",
        "Your question/feedback has been submitted and our team will get back to you very soon!",
        ""
      );
      this.setState({
        feedback: "",
      });
    }
  }
  calculateExistingLoans() {
    if (this.chart) {
      this.chart.destroy();
    }
    const { customer, eligibilityResults, loans, shouldConvertCentsToDollars } = this.state;
    if (Object.keys(customer).length > 0 && eligibilityResults.length > 0) {
      const employerMatch =
        customer.match.length > 0 ? customer.match[0].amount : 0;

      const extraPayment = customer.extraPayment / 100;
      const extraPaymentEmployerMatch = extraPayment + employerMatch;

      const existingPlan = eligibilityResults.find(function (value) {
        return value.name === "Existing";
      });
      const name = existingPlan.name;
      const eligibilityLoans = existingPlan.loans;

      let resultWithMatchAndExtra = null;
      let resultWithExtra = null;
      let result = null;
      let resultWithMatch = null;

      //will convert CentsToDollars only once when component has loaded for the first time, else it'll keep on converting it each time slider is moved
      const customerData = calculatorTranslatorData(shouldConvertCentsToDollars, customer, eligibilityLoans);

      resultWithMatchAndExtra = calculatorResultWithMatchAndExtra(
        customerData,
        name,
        extraPaymentEmployerMatch
      );
      resultWithExtra = calculatorResultWithExtra(
        customerData,
        name,
        extraPayment
      );
      result = calculatorResult(customerData, name, 0);
      resultWithMatch = calculatorResultWithMatch(
        customerData,
        name,
        employerMatch
      );

      if (
        resultWithMatchAndExtra !== null &&
        resultWithExtra !== null &&
        result !== null &&
        resultWithMatch != null
      ) {
        existingPlan["resultWithMatchAndExtra"] = resultWithMatchAndExtra;
        existingPlan["resultWithExtra"] = resultWithExtra;
        existingPlan["result"] = result;
        existingPlan["resultWithMatch"] = resultWithMatch;
      }

      const loansArray = existingPlan.result.borrower.loans;
      const existingLoansAdvisable = loansArray.every(function (e) {
        return e.advisable === true;
      });

      const employerMatchAvailable = customer.employerMatchAvailable;
      const hasLifetimeCap =
        customer.lifetimeCap &&
          (customer.lifetimeCap.employer > 0 || customer.lifetimeCap.customer > 0)
          ? true
          : false;
      const match = customer.match;
      const isVesting =
        match && (match.length > 1 || (match[0] && match[0]["month"] > 1));
      const matchEnabled =
        employerMatchAvailable &&
        !isVesting &&
        !hasLifetimeCap &&
        existingLoansAdvisable;
      const matchAmount = match.length > 0 ? match[0]["month"] : 0;

      existingPlan["extraPayment"] = extraPayment;
      existingPlan["matchEnabled"] = matchEnabled ? matchEnabled : false;
      existingPlan["matchAmount"] = matchAmount;
      existingPlan["existingLoansAdvisable"] = existingLoansAdvisable;

      this.setState({
        existingPlan: existingPlan,
        existingLoansAdvisable: existingLoansAdvisable,
        existingLoans: loans,
        matchEnabled: matchEnabled,
        extraAmountValue: extraPayment,
      });
      this._renderChart();
    }
  };
  handelRangeTracker = (e) => {
    const controlVal = e.target.value;
    this.setState({
      extraAmountValue: controlVal,
    });
  };
  trackExtraPayment = (e) => {
    const { customer_array, customer } = this.state;
    const { updateCustomer, trackEvent } = this.props;
    const controlVal = e.target.value;
    customer_array.attributes.extra_payment_cents = controlVal * 100;
    customer.extraPayment = controlVal * 100;
    const jsonRequest = {
      data: customer_array,
    };
    updateCustomer(jsonRequest);
    const data = {
      data: {
        event_key: eventNames.selectedPayFasterPlan,
        params: { extra_payment: controlVal },
      },
    };
    trackEvent(data);
    this.setState({
      extraAmountValue: controlVal,
      customer_array: customer_array,
      customer: customer
    },
      () => {
        this.calculateExistingLoans();
      });
  };
  _renderChart() {
    const avalancheStrategyData = this._getChartData("avalanche");
    const snowballStrategyData = this._getChartData("snowball");
    const noneStrategyData = this._getChartData("none");

    const labels = noneStrategyData.map((dataRow) => {
      return dataRow.x;
    });
    const stepSize = this._getStepSize(labels.length);

    this.chartData = {
      type: "line",
      data: {
        datasets: [
          {
            type: "line",
            label: "Avalanche",
            fill: false,
            data: avalancheStrategyData,
            backgroundColor: "#17c977",
            borderColor: "#17c977",
          },
          {
            type: "line",
            label: "Snowball",
            fill: false,
            data: snowballStrategyData,
            backgroundColor: "#ff7843",
            borderColor: "#ff7843",
          },
          {
            type: "line",
            label: "Current plan",
            fill: false,
            data: noneStrategyData,
            backgroundColor: "#8f8f8f",
            borderColor: "#8f8f8f",
          },
        ],
        labels: labels,
      },
      options: {
        legend: {
          labels: {
            usePointStyle: true,
          },
          onHover: (e) => {
            e.target.style.cursor = "pointer";
          },
          onLeave: (e) => {
            e.target.style.cursor = "default";
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return (
                "Outstanding Amount: " +
                this._toCurrency(tooltipItem.yLabel.toFixed(0))
              );
            },
            title: ([tooltipItem]) => {
              const monthSuffix = this._getSuffix(tooltipItem.xLabel);
              if (tooltipItem.datasetIndex === 0) {
                return (
                  tooltipItem.xLabel +
                  monthSuffix +
                  " month using avalanche strategy"
                );
              } else if (tooltipItem.datasetIndex === 1) {
                return (
                  tooltipItem.xLabel +
                  monthSuffix +
                  " month using snowball strategy"
                );
              } else {
                return (
                  tooltipItem.xLabel + monthSuffix + " month using current plan"
                );
              }
            },
          },
        },
        scales: {
          yAxes: [
            {
              type: "linear",
              scaleLabel: {
                display: true,
                labelString: "Outstanding Amount",
              },
              ticks: {
                callback: function (value) {
                  return "$" + value;
                },
              },
            },
          ],
          xAxes: [
            {
              type: "linear",
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
              ticks: {
                min: 1,
                stepSize: stepSize,
                callback: function (value) {
                  const years = Math.floor(value / 12);
                  const months = value % 12;
                  if (years > 0) {
                    if (months > 0) {
                      return `${years}y ${months}m`;
                    } else {
                      return `${years}y`;
                    }
                  } else {
                    return `${months} m`;
                  }
                },
              },
            },
          ],
        },
      },
    };
    this.createAvalancheChartWithAmCharts(this.chartData);
    this.createSnowballChartWithAmCharts(this.chartData);
    // this.chart = new Chart(this._getCanvasElement(), this.chartData);
  }
  createAvalancheChartWithAmCharts = (chartData) => {
    let avalancheData = chartData?.data?.datasets.filter((x) => x.label === "Avalanche")[0].data
    let currentData = chartData?.data?.datasets.filter((x) => x.label === "Current plan")[0].data

    let mergedData = [];

    currentData.forEach((element, i) => {
      let obj;
      if (!isNaN(avalancheData[i]?.y)) {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
          avalanche: currency_format(avalancheData[i].y),
        }
      } else {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
        }
      }
      mergedData.push(obj)
    });

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.addLicense("ch-custom-attribution");
    // Create chart instance
    var chart = am4core.create("avalancheAmCharts", am4charts.XYChart);

    // Add data
    chart.data = mergedData;
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.title.text = "Month";
    categoryAxis.renderer.minGridDistance = 50;
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Outstanding Amount";
    valueAxis.renderer.labels.template.adapter.add("text", function (text, target) { return '$' + text; });

    let label = categoryAxis.renderer.labels.template;
    label.rotation = -90;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "current";
    series.dataFields.categoryX = "month";
    series.strokeWidth = 3;
    series.strokeDasharray = "3,4";
    series.minBulletDistance = 10;
    series.tooltipText = "[bold]{month} using Current Plan\n[bold]Outstanding Amount:[/] {current}";
    // series.tooltip.pointerOrientation = "vertical";
    series.stroke = am4core.color("#898989");
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#D5D5D5");
    series.tooltip.label.fill = am4core.color("#000");
    // Create series
    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "avalanche";
    series2.dataFields.categoryX = "month";
    series2.strokeWidth = 3;
    series2.strokeDasharray = "3,4";
    series2.tooltipText = "[bold]{month} using Avalanche Plan\n[bold]Outstanding Amount:[/] {avalanche}";
    series2.stroke = series.stroke;
    series2.stroke = am4core.color('var(--icon-color)');
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color("#D5D5D5");
    series2.tooltip.label.fill = am4core.color('var(--icon-color)');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }
  createSnowballChartWithAmCharts = (chartData) => {
    let snowballData = chartData?.data?.datasets.filter((x) => x.label === "Snowball")[0].data
    let currentData = chartData?.data?.datasets.filter((x) => x.label === "Current plan")[0].data

    let mergedData = [];

    currentData.forEach((element, i) => {
      let obj;
      if (!isNaN(snowballData[i]?.y)) {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
          snowball: currency_format(snowballData[i].y),
        }
      } else {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
        }
      }
      mergedData.push(obj)
    });

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.addLicense("ch-custom-attribution");
    // Create chart instance
    var chart = am4core.create("snowballAmCharts", am4charts.XYChart);

    // Add data
    chart.data = mergedData;
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.title.text = "Month";
    categoryAxis.renderer.minGridDistance = 50;
    // categoryAxis.renderer.labels.template.adapter.add("text", function (text, target) { return text; });
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Outstanding Amount";
    valueAxis.renderer.labels.template.adapter.add("text", function (text, target) { return '$' + text; });

    let label = categoryAxis.renderer.labels.template;
    label.rotation = -90;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "current";
    series.dataFields.categoryX = "month";
    series.strokeWidth = 3;
    series.strokeDasharray = "3,4";
    series.minBulletDistance = 10;
    series.tooltipText = "[bold]{month} using Current Plan\n[bold]Outstanding Amount:[/] {current}";
    // series.tooltip.pointerOrientation = "vertical";
    series.stroke = am4core.color("#898989");
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#D5D5D5");
    series.tooltip.label.fill = am4core.color("#000");
    // Create series
    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "snowball";
    series2.dataFields.categoryX = "month";
    series2.strokeWidth = 3;
    series2.strokeDasharray = "3,4";
    series2.tooltipText = "[bold]{month} using Avalanche Plan\n[bold]Outstanding Amount:[/] {snowball}";
    series2.stroke = series.stroke;
    series2.stroke = am4core.color('var(--icon-secondary-color)');
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color("#D5D5D5");
    series2.tooltip.label.fill = am4core.color('var(--icon-secondary-color)');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }
  getYearFromMonth(month) {
    return payoffTimeInWords('%ys%ms', month)
  }
  _getCanvasElement() {
    return document
      .getElementById("amortization-graph__canvas")
      .getContext("2d");
  }
  _getChartData(type) {
    const { extraAmountValue } = this.state;
    const totalExtraPayment = parseInt(extraAmountValue) + this._getContribution();

    const amortizationData = repayment_calculator(this._getCustomerData(), {
      program: this.state.existingPlan.name,
      tableRequired: true,
      strategy: type,
      currentExtraPayment: totalExtraPayment,
    }).amortizationTable;

    return amortizationData.map((row) => {
      return { x: row.month, y: row.outstanding };
    });
  }
  _toCurrency(num) {
    return "$" + num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  _getSuffix(num) {
    return (
      ["st", "nd", "rd"][((((parseInt(num) + 90) % 100) - 10) % 10) - 1] || "th"
    );
  }
  _getContribution() {
    const { customer, matchEnabled } = this.state;
    if (matchEnabled) {
      return customer.match?.[0]?.["amount"];
    } else {
      return 0;
    }
  }
  _getCustomerData() {
    const { customer_array, existingPlan } = this.state;
    const jsCustomer = convertCamelCaseCustomer(customer_array);
    const jsLoans = existingPlan.loans;
    jsCustomer.loans = jsLoans;

    this.setState({
      shouldConvertCentsToDollars: false,
    });
    return jsCustomer;
  }
  _getStepSize(sequenceLength) {
    if (sequenceLength <= 30) {
      return 1;
    } else if (sequenceLength <= 60) {
      return 2;
    } else if (sequenceLength <= 90) {
      return 3;
    } else if (sequenceLength <= 120) {
      return 4;
    } else {
      return 5;
    }
  }
  toPaperwork = (e) => {
    // if (this.state.extraAmountValue > 0) {
    // this.props.history.push({
    //   pathname: "/advice-and-tools/pay-faster/details",
    // });
    this.setState({
      showPaperwork: !this.state.showPaperwork,
    });
    intercomTrackEvent(eventNames.clickedLearnMoreOnPayFaster);
    // }
  };
  trackOneOnOneEvent = (e) => {
    const url = "https://calendly.com/advisor-session/advisor-session";
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;

    intercomTrackEvent(eventNames.clickedScheduleOneOnOnePayOffFaster);
  };
  focusOnFeedBack = (e) => {
    scroller.scrollTo("form_box_area", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  handleChangeFeedback = (e) => {
    this.setState({
      feedback: e.target.value,
    });
  };
  submitFeedback = (e) => {
    if (this.validator.allValid()) {
      const { feedback, customer } = this.state;
      const { sendEmailFeedback } = this.props;
      const source =
        "source:Advisor\nabout:Feedback form submission\n\n" + feedback;

      const feedbackJson = {
        mail_to: customer.advisorSupportEmail,
        feedback: source,
      };
      sendEmailFeedback(feedbackJson);
      intercomTrackEvent(eventNames.submittedFeedbackFormAdvisorDashboard);
    } else {
      this.validator.showMessages();
    }
  };
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false,
        },
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true,
        },
      });
    }
  }
  render() {
    const {
      customer,
      existingPlan,
      existingLoansAdvisable,
      matchEnabled,
      extraAmountValue,
      showPaperwork,
    } = this.state;

    return (

      <>
        <Loader externalLoading={this.state.externalLoading} />
        <Header />

        <div className="G-page-background-color">
          <div className="G-page-main-container  my-5">
            <Breadcrumb list={AdviceAndToolPayFasterBreadcrumb} />
            <div className="p-4 bg-white Gborder-1 rounded-lg">
              <div className="d-flex">
                <div className="pinkCircularDiv ">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M28.75 33.75H22.5L31.25 16.25V26.25H37.5L28.75 43.75V33.75Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h2 className="my-auto ml-3">Payoff Faster</h2>
              </div>

              <label className="my-3 mt-4 Gfs-15 sans-semibold">
                Learn how much faster you can payoff your loan each month
              </label>

              <PayFasterExtraWidget
                program={existingPlan}
                customer={customer}
                existingLoansAdvisable={existingLoansAdvisable}
                matchEnabled={matchEnabled}
                extraAmountValue={extraAmountValue}
                handelRangeTracker={this.handelRangeTracker.bind(this)}
                trackExtraPayment={this.trackExtraPayment.bind(this)}
              />

              <div className="Gborder-05 rounded-lg p-4 mt-3">
                <div className="mt-2 d-flex G-cursor-pointer" onClick={() => this.setState({ learnMoreTypesShow: !this.state.learnMoreTypesShow })}>
                  <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
                    Learn more about the payment methods
                  </label>
                  <div className="ml-auto my-auto">
                    {
                      this.state.learnMoreTypesShow === false ?
                        <div className="ml-auto my-auto G-cursor-pointer" >
                          <PlusCircle size={22} />
                        </div> :
                        <div className="ml-auto my-auto G-cursor-pointer">
                          <DashCircle size={22} />
                        </div>
                    }
                  </div>
                </div>
                {this.state.learnMoreTypesShow &&
                  <>
                    <div className="G-grid11-2 mt-4">
                      <div>
                        <div className="d-flex">
                          <div className="my-auto">
                            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                              <g clipPath="url(#clip0_2807_5107)">
                                <path d="M14.1414 15.7657L12.1414 13.099H8.14136V22.4324H21.4747L17.4747 17.099L14.1414 15.7657ZM15.1614 17.6124L10.808 19.0657L9.47469 18.619V17.359L10.808 17.8057L13.4414 16.9257L15.1614 17.6124ZM11.4747 14.4324L12.5214 15.8257L10.808 16.399L9.47469 15.9524V14.4324H11.4747ZM9.47469 21.099V20.0257L10.808 20.4724L16.828 18.4657L18.808 21.099H9.47469ZM18.1414 11.7657V8.43235L14.808 7.76569L12.808 9.09902V11.7657L14.808 13.099L18.1414 11.7657ZM14.1414 9.81235L15.088 9.17902L16.808 9.52569V10.8657L14.9614 11.6057L14.1414 11.0524V9.81235ZM19.1414 12.4324L17.4747 13.7657V15.7657L19.1414 17.099L22.1414 15.7657V13.099L19.1414 12.4324ZM20.808 14.899L19.3414 15.5524L18.808 15.1257V14.4057L19.4747 13.8724L20.808 14.1657V14.899Z" fill="var(--icon-color)" />
                              </g>
                              <defs>
                                <clipPath id="clip0_2807_5107">
                                  <rect width="16" height="16" fill="white" transform="translate(7 7.5)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <label className="mb-0 Gfs-125 ml-2">
                            Avalanche Method
                          </label>
                        </div>
                        <p className="sans-medium mt-4">
                          The debt avalanche method targets debts with the highest interest rates first. Order your student loans from highest interest rate to lowest. Focus on paying extra funds towards the loan with the highest interest rate, while continuing to make minimum payments on the other student loans. Once you payoff the student loan with the highest interest rate, focus the extra funds on the debt with the next-highest interest rate. Continue this strategy until all loans have been repaid. The debt avalanche method targets debts with the highest interest rates first.
                        </p>
                        <p className="sans-bold my-3">
                          How do I do it?
                        </p>
                        <div className="d-flex">
                          <p className="sans-medium">1.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Order your student loans from highest interest rate to lowest.
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="sans-medium">2.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Focus on paying extra funds towards the loan with the hightest interest rate,
                            while continuing to make minimum payments on the other student loans.
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="sans-medium">3.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Once you payoff the student loan with the hightest interest
                            rate, focus the extra funds on the debt with the next-highest interest rate.
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="sans-medium">4.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Continue this strategy until all loans have been repaid.
                          </p>
                        </div>
                      </div>


                      <div>
                        <div className="d-flex">
                          <div className="my-auto">
                            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                              <path d="M14.9999 10.1667C17.9399 10.1667 20.3333 12.56 20.3333 15.5C20.3333 18.44 17.9399 20.8333 14.9999 20.8333C12.0599 20.8333 9.66659 18.44 9.66659 15.5C9.66659 12.56 12.0599 10.1667 14.9999 10.1667ZM14.9999 8.83334C11.3199 8.83334 8.33325 11.82 8.33325 15.5C8.33325 19.18 11.3199 22.1667 14.9999 22.1667C18.6799 22.1667 21.6666 19.18 21.6666 15.5C21.6666 11.82 18.6799 8.83334 14.9999 8.83334Z" fill="var(--icon-color)" />
                            </svg>
                          </div>
                          <label className="mb-0 Gfs-125 ml-2">
                            Snowball Method
                          </label>
                        </div>
                        <p className="sans-medium mt-4">
                          The debt snowball method targets the smallest debt first.
                        </p>
                        <p className="sans-bold my-3">
                          How do I do it?
                        </p>
                        <div className="d-flex">
                          <p className="sans-medium">1.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Order your student loans from lowest balance to highest balance.
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="sans-medium">2.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Focus on paying extra funds towards the loan with the lowest balance,
                            while continuing to make minimum payments on the other student loans.
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="sans-medium">3.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Once you payoff the student loan with the lowest balance,
                            focus the extra funds on the debt with the next-lowest balance.
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="sans-medium">4.&nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            Continue this strategy until all loans have been repaid.
                          </p>
                        </div>
                      </div>
                    </div >
                    <div className="G-grid11-2 mt-4">
                      <div>
                        <div className="d-flex">
                          <div className="my-auto">
                            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                              <path d="M15.6666 10.8867C17.7066 11.18 19.3199 12.7934 19.6133 14.8334H21.6333C21.3199 11.6867 18.8133 9.18003 15.6666 8.8667V10.8867ZM19.6133 16.1667C19.3199 18.2067 17.7066 19.82 15.6666 20.1134V22.1334C18.8133 21.82 21.3199 19.3134 21.6333 16.1667H19.6133ZM14.3333 20.1134C12.0733 19.7867 10.3333 17.8467 10.3333 15.5C10.3333 13.1534 12.0733 11.2134 14.3333 10.8867V8.8667C10.9666 9.20003 8.33325 12.04 8.33325 15.5C8.33325 18.96 10.9666 21.8 14.3333 22.1334V20.1134Z" fill="var(--icon-color)" />
                            </svg>
                          </div>
                          <label className="mb-0 Gfs-125 ml-2">
                            Standard Method
                          </label>
                        </div>
                        <p className="sans-medium mt-4">
                          Standard assumes that you pay the minimum monthly payments for all loans.
                        </p>
                        <p className="sans-bold my-3">
                          What else should you consider when selecting a payoff strategy?
                        </p>
                        <p className="sans-medium">
                          If you thrive on long-term projects, consider the debt avalanche method.
                        </p>
                        <div className="d-flex">
                          <p className="sans-medium mx-1"> &#183; &nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            The debt avalanche method eliminates your most
                            expensive debts first, and saves you the most on interest.
                            However, paying the highest-interest rate debt first can
                            take some time.
                          </p>
                        </div>
                        <p className="sans-medium">
                          If quick wins drive your motivation, consider the debt snowball method.
                        </p>
                        <div className="d-flex">
                          <p className="sans-medium mx-1"> &#183; &nbsp;&nbsp;</p>
                          <p className="sans-medium">
                            If you prefer short-term victories to fuel your motivation to
                            continue your payoff plan, you may prefer the debt snowball method.
                          </p>
                        </div>
                      </div>
                    </div >
                  </>
                }
              </div>

              <div className="Gborder-05 rounded-lg p-4 mt-3">
                <div className="mt-2 d-flex G-cursor-pointer" onClick={this.toPaperwork.bind(this)}>
                  <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
                    Apply to lower your minimum payment
                  </label>
                  <div className="ml-auto my-auto " onClick={this.toPaperwork.bind(this)}>
                    {showPaperwork ? <DashCircle size={22} />
                      :
                      <PlusCircle size={22} />}
                  </div>
                </div>
                {showPaperwork ? (
                  <PayFasterPaperWork
                    originalPlan={existingPlan}
                    newPlan={existingPlan}
                    customer={customer}
                    existingLoansAdvisable={existingLoansAdvisable}
                    matchEnabled={matchEnabled}
                    extraAmountValue={extraAmountValue}
                    trackOneOnOneEvent={this.trackOneOnOneEvent.bind(this)}
                    focusOnFeedBack={this.focusOnFeedBack.bind(this)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

PayFaster.propTypes = {
  saveReturnTrip: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getLoans: PropTypes.func.isRequired,
  getEligibilityResults: PropTypes.func.isRequired,
  sendEmailFeedback: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  returnTrip: state.advisor.returnTrip,
  customer: state.customer_array.customerData,
  updateCustomerResponse: state.customer_array.updateCustomerResponse,
  loans: state.five_two_nine.loans,
  eligibilityResults: state.five_two_nine.eligibilityResults,
  emailFeedbackResponse: state.advisor.emailFeedbackResponse,
  trackEventResponse: state.advisor.trackEventResponse,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  saveReturnTrip,
  fetchCustomer,
  getLoans,
  getEligibilityResults,
  updateCustomer,
  sendEmailFeedback,
  trackEvent,
})(PayFaster);
